<template>
    <div id="contact" class="page-wrapper page-contact">
        <!-- Hero -->
        <header class="small-hero">
            <article class="content" v-if="pageIsLoaded">
                <h1 class="title sup-title" data-inview="fadeInUp" data-delay="100">{{ content.hero.surtitre }}</h1>
                <h2 class="subtitle big-title" data-inview="fadeInUp" data-delay="300"><span v-html="content.hero.titre"></span></h2>
                <p class="regular-text contact-infos" data-inview="fadeInUp" data-delay="400">
                    <a href="https://www.google.com/maps/dir//3275+Av+Francis-Hughes,+Laval,+QC+H7L+5A5/@45.5941539,-73.7724293,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4cc9216a251b9c9d:0x9e4d37fb00d5f3b5!2m2!1d-73.73741!2d45.5941592!3e0" target="_blank" rel="noopener noreferrer" title="Directions">
                        <svg height="28" viewBox="0 0 25 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4995 17.086C9.75722 17.086 7.53279 14.9077 7.53279 12.2186C7.53279 9.52956 9.75706 7.34973 12.4995 7.34973C15.2433 7.34973 17.4677 9.52952 17.4677 12.2186C17.4677 14.9078 15.2435 17.086 12.4995 17.086ZM24.3039 8.15322C24.0846 7.545 23.8155 6.95357 23.4998 6.38805C23.184 5.821 22.8232 5.27831 22.4203 4.76617C22.0175 4.25552 21.5741 3.77534 21.0951 3.33176C20.6145 2.88666 20.098 2.47813 19.5521 2.11229C19.0015 1.7434 18.4197 1.41873 17.8162 1.14128C17.2033 0.860801 16.5672 0.629102 15.9155 0.450767C15.2482 0.26632 14.5653 0.1398 13.8763 0.0696831C13.0596 -0.0141541 12.2259 -0.0217793 11.4078 0.0437689C10.7156 0.0986432 10.0281 0.211447 9.35616 0.379125C8.69978 0.54223 8.05892 0.760209 7.4383 1.02851C6.82858 1.29222 6.24061 1.6032 5.68218 1.95987C5.12845 2.31199 4.60427 2.70833 4.11274 3.14274C3.62587 3.57414 3.17169 4.04515 2.75796 4.54667C2.3442 5.04663 1.97245 5.58016 1.6427 6.13807C1.3145 6.69903 1.02984 7.28437 0.794986 7.88954C0.760766 7.97643 0.728102 8.06484 0.696995 8.15325C-0.0340497 10.1776 -0.22383 12.4243 0.289482 14.708C1.49649 20.0935 6.03861 24.2733 8.81004 28.9225C9.0558 29.3341 11.3392 32.9239 12.1667 34.7744V34.7759C12.2196 34.907 12.3487 35 12.4996 35C12.652 35 12.7811 34.907 12.834 34.7759V34.7744C13.6615 32.9238 15.9449 29.3342 16.1906 28.9225C18.9624 24.2733 23.5043 20.0935 24.7112 14.708C25.223 12.4245 25.0347 10.1778 24.3037 8.15325" fill="#59BBEB"/>
                        </svg>
                        <span v-html="globals.contact.contactInfos.adresse"></span>
                    </a>
                    <a :href="'tel:' + globals.contact.contactInfos.telephone" title="tel">
                        <svg height="28" viewBox="0 0 20 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.61695 0H17.3831C18.8167 0 20 1.20858 20 2.67283V32.3272C20 33.7914 18.8167 35 17.3831 35H2.61695C1.18331 35 0 33.7914 0 32.3272V2.67283C0 1.20858 1.18331 0 2.61695 0V0ZM8.22423 32.5814C7.85078 32.5814 7.60178 32.3271 7.60178 31.9456C7.60178 31.5642 7.85076 31.3099 8.22423 31.3099H11.8385C12.1504 31.3099 12.4609 31.5642 12.4609 31.9456C12.4609 32.3271 12.149 32.5814 11.8385 32.5814H8.22423ZM1.74414 4.32703V28.8913H18.3172V4.32703H1.74414Z" fill="#59BBEB"/>
                        </svg>
                        {{ globals.contact.contactInfos.telephone }}
                    </a>
                    <a :href="'mailto:' + globals.contact.contactInfos.courriel" title="mail">
                        <svg width="20" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.87498 0.000230639C1.50633 0.000230639 1.1682 0.109604 0.878891 0.288688L11.582 9.4904C12.1472 9.97716 12.832 9.97716 13.3984 9.4904L24.1209 0.278842C23.8328 0.100962 23.491 0 23.1248 0L1.87498 0.000230639ZM0.107422 1.25021C0.042725 1.43771 0 1.63602 0 1.84636V14.1539C0 15.1767 0.836188 16 1.875 16H23.125C24.1638 16 25 15.1767 25 14.1539V1.84636C25 1.63603 24.9573 1.43772 24.8926 1.25021L14.2188 10.423C13.224 11.2775 11.7555 11.2691 10.762 10.4134L0.107422 1.25021Z" fill="#59BBEB"/>
                        </svg>
                        {{ globals.contact.contactInfos.courriel }}
                    </a>
                </p>
            </article>
            <div class="image" data-inview="revealRight" data-delay="100">
                <img v-if="pageIsLoaded" :src="content.hero.image.url" :alt="content.hero.image.titre" >
            </div>
        </header>

        <div class="site-max-width off-mobile">
            <section id="form-container" class="form-container">
                <h3 class="regular-title" data-inview="fadeInUp" data-delay="100" v-if="pageIsLoaded">{{ content.introBlock1.surtitre }}</h3>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300" v-if="pageIsLoaded"><span v-html="content.introBlock1.titre"></span></p>
                <form
                    class="site-form"
                    name="postuler"
                    action=""
                    method="post"
                    accept-charset="UTF-8"
                    @submit="sendForm($event)"
                    enctype="multipart/form-data"
                    data-inview="fadeInUp" data-delay="400"
                >
                    <input type="hidden" name="action" value="contact-form/send" />

                    <label for="nom">{{ $t('Nom') }}</label>
                    <input type="text" id="nom" name="nom" v-model="formData.nom" :placeholder="$t('Nom')" required />

                    <label for="prenom">{{ $t('Prénom') }}</label>
                    <input type="text" id="prenom" name="prenom" v-model="formData.prenom" :placeholder="$t('Prénom')" required />

                    <label for="telephone">{{ $t('Téléphone') }}</label>
                    <input type="text" id="telephone" name="telephone" v-model="formData.telephone" :placeholder="$t('Téléphone')" />

                    <label for="email">{{ $t('Courriel') }}</label>
                    <input type="email" id="email" name="email" v-model="formData.courriel" :placeholder="$t('Courriel')" required />

                    <label for="heures">{{ $t('Heure préférable pour contacter') }}</label>
                    <input type="text" id="heures" name="heures" v-model="formData.heures" :placeholder="$t('Heure préférable pour contacter')" />

                    <label for="message">{{ $t('Message') }}</label>
                    <textarea id="message" name="message" v-model="formData.message" :placeholder="$t('Message')"></textarea>

                    <input class="site-btn alternate" type="submit" :value="$t('Envoyer')" v-if="!isSending" />
                </form>
            </section>
        </div>
    </div>
</template>

<script>
import { initInViewAnimations } from '@/plugins/InViewAnimations'

import axios from 'axios'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

import { mapGetters, mapState } from 'vuex'

export default {
    name: 'Contact',

    data() {
        return {
            formMessage: '',
            formMessageStatus: '',
            isSending: false,
            formData: {
                nom: '',
                prenom: '',
                telephone: '',
                courriel: '',
                heures: '',
                message: '',
            }
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    initInViewAnimations()
                }
            },
            immediate: true,
        },
    },

    mounted() {

    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        async sendForm(e) {
            e.preventDefault()

            if (this.isSending) {
                return
            }

            this.isSending = true

            // this.formData[this.content.content.form.csrfName] = this.content.content.form.csrf
            this.formData['action'] = 'contact-form/send'
            this.formData['formName'] = 'Contact'
            this.formData['sujet'] = 'Contact'

            const formData = new FormData()
            formData.append('nom', this.formData.nom)
            formData.append('prenom', this.formData.prenom)
            formData.append('telephone', this.formData.telephone)
            formData.append('courriel', this.formData.courriel)
            formData.append('heures', this.formData.heures)
            formData.append('message', this.formData.message)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

             await axios
                .post(`${process.env.VUE_APP_SITE_BASE_URL}/api/send/contact-form`, formData, config)
                .then(res => {
                    this.formMessage = 'success'
                    this.formMessageStatus = res.status

                    Swal.fire({
                        text: this.$t('Votre message a été envoyé avec succès.'),
                        icon: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                    setTimeout(() => {
                        this.formData = {}

                        this.formMessage = ''
                        this.isSending = false
                    }, 2275)
                })
                .catch((e) => {
                    this.formMessage = e

                    setTimeout(() => {
                        this.formData = {
                            fromName: '',
                            fromEmail: '',
                            phone: '',
                            subject: '',
                            message: '',
                        }

                        this.formMessage = ''
                        this.isSending = false
                    }, 2275)
                })
        },
    },
}
</script>
